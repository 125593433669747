import { createSelector } from 'reselect'

export const getCheckoutState = (state) => state.checkout

export const getCheckoutError = createSelector([getCheckoutState], (checkout) => checkout.error)

export const getDepotMismatch = createSelector([getCheckoutState], (checkout) => checkout.depotMismatch)

export const getCheckoutMessage = createSelector([getCheckoutState], function (checkout) {
  return checkout.message
})

export const getCheckoutMessageStatusCode = createSelector([getCheckoutState], function (checkout) {
  return checkout.messageStatusCode
})

export const getCheckoutNotes = createSelector([getCheckoutState], (checkout) => checkout.notes)

export const getDeliveryInstructionNotes = createSelector([getCheckoutState], (checkout) => checkout.deliveryNotes)
export const getisACHDeclineDrawerOpen = createSelector(
  [getCheckoutState],
  (checkout) => checkout.isACHDeclineDrawerOpen
)
export const getisACHFixDrawerOpen = createSelector([getCheckoutState], (checkout) => checkout.isACHFixDrawerOpen)
