/**
 * Shared timing functions so that estimates and orders can show the same time ranges
 */

const PADDING_MULTIPLIER = 0.2 // Add 20% padding to the interval
const MAX_PADDING = 600 // Never display an interval > 10 min
const MIN_PADDING_TO_DISPLAY = 120 // We don't display the interval if padding is < 2 min

/**
 * Computes an ETA interval based on the eta parameter
 * @params {String} estimate - estimatated time to delivery in minutes
 * @params {Boolean} forceNoPadding - true will make the returned estimate not have a high number
 * @return {Object {
 *           low,  // lower side of the interval in seconds (unmodified eta)
 *           high  // higher side of the interval in seconds
 * }}
 */
export const etaInterval = (estimate, forceNoPadding) => {
  const padding = Math.min(estimate * PADDING_MULTIPLIER, MAX_PADDING)
  if (forceNoPadding || estimate < MIN_PADDING_TO_DISPLAY) {
    return {
      low: estimate
    }
  }

  return {
    low: estimate,
    high: estimate + padding
  }
}

export const etaIntervalFormatted = (interval) => {
  if (interval.high) {
    return `${Math.round(interval.low / 60)}-${Math.round(interval.high / 60)} min`
  }
  return `${Math.max(1, Math.round(interval.low / 60))} min`
}
