import { createSelector } from 'reselect'
import { format } from 'fecha'
import { convertStringToIso, convertTimeStringToMinutesUntil } from './time-parsers'
import {
  ORDER_CANCELED_TYPE_ARRAY,
  ORDER_ETA_FLOOR_MINUTES,
  ORDER_PROGRESS_TYPE,
  ORDER_PROGRESS_TYPE_ARRAY,
  ORDER_STATUS_TYPES
} from './constants'

export const getOrderStatus = (state) => state.orderStatus

export const getIsOrderStatusLoading = createSelector(
  [getOrderStatus],
  (orderStatus) => orderStatus.isOrderStatusLoading
)

export const getOrderStatusData = createSelector([getOrderStatus], (orderStatus) => orderStatus.statusData)

export const getOrderStatusTrackerData = createSelector([getOrderStatus], (orderStatus) => orderStatus.trackers)

export const getOrderStatusType = createSelector([getOrderStatusData], (orderStatusData) => {
  return orderStatusData.status
})

export const getIsOrderCanceledByDriver = createSelector([getOrderStatusType], (orderStatusType) => {
  return orderStatusType === ORDER_STATUS_TYPES.CANCELED_BY_DRIVER
})

const getOrderDeliveredAt = createSelector([getOrderStatusData], (orderStatusData) => orderStatusData.deliveredAt)

export const getOrderDeliveredAtFormatted = createSelector([getOrderDeliveredAt], (orderDeliveredAt) => {
  if (orderDeliveredAt) {
    const deliveredAtTimestamp = convertStringToIso(orderDeliveredAt)
    return format(new Date(Date.parse(deliveredAtTimestamp)), 'h:mm a')
  }
  return ''
})

export const getIsOrderCanceled = createSelector([getOrderStatusType], (orderStatusType) =>
  ORDER_CANCELED_TYPE_ARRAY.includes(orderStatusType)
)

export const getIsOrderDelivered = createSelector([getOrderStatusType], (orderStatusType) => {
  return orderStatusType === ORDER_STATUS_TYPES.DELIVERED
})

export const getDeliveryExpectedStartAt = createSelector([getOrderStatusData], (orderStatusData) => {
  return orderStatusData.deliveryExpectedStartAt
})

export const getDeliveryExpectedEndAt = createSelector([getOrderStatusData], (orderStatusData) => {
  return orderStatusData.deliveryExpectedEndAt
})

const getMinutesUntilDeliveryExpectedStartAt = createSelector(
  [getDeliveryExpectedStartAt],
  (deliveryExpectedStartAt) => {
    const minutesUntilStart = deliveryExpectedStartAt ? convertTimeStringToMinutesUntil(deliveryExpectedStartAt) : ''
    return minutesUntilStart
  }
)

const getMinutesUntilDeliveryExpectedEndAt = createSelector([getDeliveryExpectedEndAt], (deliveryExpectedEndAt) => {
  const minutesUntilEnd = deliveryExpectedEndAt ? convertTimeStringToMinutesUntil(deliveryExpectedEndAt) : ''
  return minutesUntilEnd
})

export const getMinutesUntilDeliveryExpected = createSelector([getOrderStatusData], (orderStatusData) => {
  const accurateEstimate = orderStatusData.deliveryExpectedAt
  const minutesUntilDeliveryExpected = accurateEstimate ? convertTimeStringToMinutesUntil(accurateEstimate) : ''
  if (isNaN(minutesUntilDeliveryExpected)) {
    return ''
  } else {
    return minutesUntilDeliveryExpected
  }
})

export const getDeliveryEtaIntervalMinutes = createSelector(
  [getMinutesUntilDeliveryExpectedStartAt, getMinutesUntilDeliveryExpectedEndAt],
  (minutesUntilStart, minutesUntilEnd) => {
    if (typeof minutesUntilStart !== 'number' || typeof minutesUntilEnd !== 'number') {
      return ''
    } else {
      return `${minutesUntilStart}-${minutesUntilEnd} min`
    }
  }
)

export const getDeliveryEtaIntervalTimestamp = createSelector(
  [getDeliveryExpectedStartAt, getDeliveryExpectedEndAt],
  (deliveryExpectedStartAt, deliveryExpectedEndAt) => {
    if (!deliveryExpectedStartAt || !deliveryExpectedEndAt) {
      return ''
    } else {
      const isoStart = convertStringToIso(deliveryExpectedStartAt)
      const isoEnd = convertStringToIso(deliveryExpectedEndAt)
      const formattedStart = format(new Date(Date.parse(isoStart)), 'h:mm a')
      const formattedEnd = format(new Date(Date.parse(isoEnd)), 'h:mm a')

      return `${formattedStart} - ${formattedEnd}`
    }
  }
)

export const getHasBeenFirstInQueue = createSelector([getOrderStatus], (orderStatus) => {
  return Boolean(orderStatus.hasBeenFirstInQueue)
})

export const getDeliveryEta = createSelector(
  [getHasBeenFirstInQueue, getDeliveryEtaIntervalTimestamp, getMinutesUntilDeliveryExpected],
  (hasBeenFirstInQueue, deliveryEtaIntervalTimestamp, minutesUntilDeliveryExpected) => {
    if (hasBeenFirstInQueue) {
      const displayText =
        minutesUntilDeliveryExpected <= ORDER_ETA_FLOOR_MINUTES
          ? 'Arriving Soon'
          : `${minutesUntilDeliveryExpected} min`
      return displayText
    } else {
      return deliveryEtaIntervalTimestamp
    }
  }
)

export const getDeliveryEtaTimestamp = createSelector(
  [getHasBeenFirstInQueue, getDeliveryEtaIntervalTimestamp, getMinutesUntilDeliveryExpected],
  (hasBeenFirstInQueue, deliveryEtaIntervalTimestamp, minutesUntilDeliveryExpected) => {
    if (hasBeenFirstInQueue) {
      return `${minutesUntilDeliveryExpected} min`
    } else {
      return deliveryEtaIntervalTimestamp
    }
  }
)

export const getIsOrderRequested = createSelector([getOrderStatusType], (orderStatusType) => {
  return orderStatusType === ORDER_STATUS_TYPES.REQUESTED
})

export const getIsOrderAccepted = createSelector([getOrderStatusType], (orderStatusType) => {
  return orderStatusType === ORDER_STATUS_TYPES.ACCEPTED
})

export const getOrderProgressType = createSelector(
  [
    getHasBeenFirstInQueue,
    getIsOrderAccepted,
    getIsOrderDelivered,
    getIsOrderRequested,
    getMinutesUntilDeliveryExpected
  ],
  (hasBeenFirstInQueue, isOrderAccepted, isOrderDelivered, isOrderRequested, minutesUntilDeliveryExpected) => {
    // getMinutesUntilDeliveryExpected will return '' if it doesn't exist, and a number if it does
    // use that and hasBeenFirstInQueue to trigger the driver is on the way flow
    const isOnTheWay = hasBeenFirstInQueue && typeof minutesUntilDeliveryExpected === 'number'
    let progressType = ORDER_PROGRESS_TYPE.ORDER_RECEIVED
    if (!hasBeenFirstInQueue && isOrderRequested) {
      progressType = ORDER_PROGRESS_TYPE.ORDER_RECEIVED
    }

    if (!hasBeenFirstInQueue && isOrderAccepted) {
      progressType = ORDER_PROGRESS_TYPE.ORDER_BEING_PREPARED
    }

    if (isOnTheWay && minutesUntilDeliveryExpected > ORDER_ETA_FLOOR_MINUTES) {
      progressType = ORDER_PROGRESS_TYPE.ORDER_ON_THE_WAY
    }

    if (isOnTheWay && minutesUntilDeliveryExpected <= ORDER_ETA_FLOOR_MINUTES) {
      progressType = ORDER_PROGRESS_TYPE.ORDER_ARRIVING
    }

    if (isOrderDelivered) {
      progressType = ORDER_PROGRESS_TYPE.ORDER_DELIVERED
    }
    return progressType
  }
)

export const getCurrentOrderProgressIndex = createSelector([getOrderProgressType], (orderProgressType) => {
  const currentOrderProgressIndex = ORDER_PROGRESS_TYPE_ARRAY.indexOf(orderProgressType)

  return currentOrderProgressIndex
})

export default {
  getDeliveryExpectedEndAt,
  getDeliveryExpectedStartAt,
  getOrderStatus,
  getIsOrderStatusLoading,
  getOrderStatusData,
  getOrderStatusTrackerData,
  getOrderStatusType,
  getIsOrderCanceledByDriver,
  getOrderDeliveredAtFormatted,
  getIsOrderCanceled,
  getIsOrderDelivered,
  getDeliveryEtaIntervalMinutes,
  getDeliveryEtaIntervalTimestamp,
  getHasBeenFirstInQueue,
  getDeliveryEta,
  getDeliveryEtaTimestamp,
  getIsOrderRequested,
  getIsOrderAccepted,
  getOrderProgressType,
  getCurrentOrderProgressIndex
}
