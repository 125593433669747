const DEFAULT_INVITEE_CREDIT = 30 // NOTE: Invitee is the new customer
const DEFAULT_INVITER_CREDIT = 40 // NOTE: Inviter is the existing customer
const SHARE_PATH_ROOT = '/share'

const copy = {
  referralCopy: (inviterCredit, inviteeCredit) =>
    `Give friends $${inviteeCredit || DEFAULT_INVITEE_CREDIT} off their first order, get $${
      inviterCredit || DEFAULT_INVITER_CREDIT
    } credit for yourself!`,

  referralCopyV2: (inviterCredit, inviteeCredit) =>
    `Send a friend $${inviteeCredit || DEFAULT_INVITEE_CREDIT} in Eaze credit and get $${
      inviterCredit || DEFAULT_INVITER_CREDIT
    } once they get their first delivery.`,

  emailReferralSubject: 'Have you heard of Eaze?',

  emailReferralMsg: (inviteUrl, inviteeCredit) =>
    `You can get cannabis delivered wherever you are. Use my link to get $${
      inviteeCredit || DEFAULT_INVITEE_CREDIT
    }  off your first order: ${inviteUrl}`,

  defaultReferralMsg: (inviteUrl, inviteeCredit) =>
    `Have you heard of Eaze? You can get cannabis delivered wherever you are. Use my link to get $${
      inviteeCredit || DEFAULT_INVITEE_CREDIT
    } off your first order: ${inviteUrl}`
}

const utm = {
  email: '?utm_source=viral-referral&utm_medium=email&utm_campaign=20x20&utm_content=standard',
  facebook: '?utm_source=viral-referral&utm_medium=facebook&utm_campaign=20x20&utm_content=standard',
  link: '?utm_source=viral-referral&utm_medium=link&utm_campaign=20x20&utm_content=standard',
  sms: '?utm_source=viral-referral&utm_medium=sms&utm_campaign=20x20&utm_content=standard',
  twitter: '?utm_source=viral-referral&utm_medium=twitter&utm_campaign=20x20&utm_content=standard'
}

const sharePaths = {
  email: SHARE_PATH_ROOT + '/e',
  facebook: SHARE_PATH_ROOT + '/f',
  link: SHARE_PATH_ROOT + '/l',
  sms: SHARE_PATH_ROOT + '/s',
  twitter: SHARE_PATH_ROOT + '/t'
}

const inviterCredit = DEFAULT_INVITER_CREDIT
const inviteeCredit = DEFAULT_INVITEE_CREDIT

export {
  copy,
  inviterCredit, // was inviteKickback
  inviteeCredit, // was inviteeKickback
  sharePaths,
  utm
}
