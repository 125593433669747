import { createSelector } from 'reselect'
import { getMenuState } from 'redux/menu/selectors'

const getAnnouncements = (state) => state.announcements

export const getDismissedAnnouncements = createSelector(
  [getAnnouncements],
  (announcements) => announcements.dismissedAnnouncements || []
)

// todo refactor this to only be in eaze.com
export const getNewestAnnouncement = createSelector(
  [getMenuState, getDismissedAnnouncements],
  (reducerState, dismissedAnnouncements) => {
    if (reducerState.announcements.length > 0) {
      // filter out announcements the user has already dismissed
      const notDismissed = reducerState.announcements.filter((announcement) => {
        return dismissedAnnouncements.indexOf(announcement.id) === -1
      })

      if (notDismissed.length > 0) {
        // return only the announcement product with the highest id number
        return notDismissed.reduce((prev, current) => {
          return current.id > prev.id ? current : prev
        })
      }
    }
  }
)
