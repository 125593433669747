/**
 * Selectors for verification
 */

import { createSelector } from 'reselect'
import {
  getPhoneVerifiedStatus,
  isBasicDeclined,
  isBasicIncomplete,
  isBasicExpired,
  isMedicalPending,
  isMedicalVerified,
  getBerbixVerificationStatus,
  getIdCardType,
  hasUsaId,
  ELIGIBILITY_PROOF_TYPES,
  BERBIX_STATUSES
} from 'redux/profile/selectors'
import { getIsExactAddress, getInSampleLocation, isIdScanRequired, isUsaIdOnly } from 'redux/location/selectors'

export const STEPS = {
  PHONE: 'phone',
  ID: 'id',
  DECLINED: 'declined',
  DONE: 'done'
}

const getVerification = (state, props) => state.verification

export const getVerificationProcessErrors = createSelector([getVerification], (verification) => verification.err)

export const getVerificationResendStatus = createSelector(
  [getVerification],
  (verification) => verification.phone.hasPhoneCodeBeenResent
)

export const getVerificationPhoneNumber = createSelector(
  [getVerification],
  (verification) => verification.phone.phoneNumber
)

export const getFlowStep = createSelector(
  [
    getPhoneVerifiedStatus,
    isBasicIncomplete,
    isBasicDeclined,
    isBasicExpired,
    getIsExactAddress,
    getInSampleLocation,
    isMedicalPending,
    isMedicalVerified,
    getBerbixVerificationStatus
  ],
  (
    isPhoneVerified,
    basicIncomplete,
    basicDeclined,
    basicExpired,
    isExactLocation,
    isSampleLocation,
    medicalPending,
    medicalVerified,
    berbixVerificationStatus
  ) => {
    if (!isPhoneVerified) {
      return STEPS.PHONE
    } else if (berbixVerificationStatus === BERBIX_STATUSES.DECLINED) {
      return STEPS.DECLINED
    } else if (basicIncomplete || basicExpired || basicDeclined) {
      return STEPS.ID
    } else {
      return STEPS.DONE
    }
  }
)

export const getPageId = createSelector([getVerification], (verification) => verification.currentPageId)

export const isIdScanApproved = createSelector(
  [getIdCardType, isIdScanRequired],
  (cardType, idScanReq) => !idScanReq || !(cardType === ELIGIBILITY_PROOF_TYPES.PASSPORT)
)

export const isIdOriginApproved = createSelector(
  [hasUsaId, isUsaIdOnly],
  (isFromUsa, isUsaOnly) => !isUsaOnly || isFromUsa
)

export const isIdValidInArea = createSelector(
  [isIdScanApproved, isIdOriginApproved],
  (idScanApproved, idOriginApproved) => idScanApproved && idOriginApproved
)
