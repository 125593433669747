import isEmptyObject from 'is-empty-object'
import { createSelector } from 'reselect'

import {
  CASH_PAYMENT_METHOD,
  CREDIT_PAYMENT_METHOD,
  PRESENT_CREDIT_CARD_PAYMENT_METHOD,
  PRESENT_DEBIT_CARD_PAYMENT_METHOD,
  DEBIT_CASH_BACK_PAYMENT_METHOD,
  DEBIT_CARD_ONLINE_METHOD,
  ACH_PAYMENT_METHOD,
  NOT_ONLINE_CARD_PAYMENT_METHOD_IDS
} from 'helpers/payment'
import { getActiveDepot } from 'redux/location/selectors'

const EMPTY_OBJ = {}

const getState = (state) => state

export const getPayments = createSelector([getState], (state) => state.payments)

export const getCards = createSelector([getPayments], (payments) => payments.cards || EMPTY_OBJ)

export const getDebitCards = createSelector([getPayments], (payments) => payments.debitCards || EMPTY_OBJ)

export const getACH = createSelector([getPayments], (payments) => payments.ach)

export const getDebitPublicKey = createSelector([getPayments], (payments) => payments.debitPublicKey)

export const getCardById = (id) => getCards()[id]

export const getActivePaymentId = createSelector([getPayments], (payments) => payments.activePaymentId)

export const getPaymentProviders = createSelector([getPayments], (payments) => payments.paymentProviders)

export const getDriverTip = createSelector([getPayments], (payments) => payments.driverTip)

export const getAchProvider = createSelector([getPaymentProviders], (providers) => providers?.[ACH_PAYMENT_METHOD])

export const getActiveCard = createSelector([getPayments], (payments) => {
  return payments.activeCard
})

export const getChargeDescriptor = createSelector([getPayments], (payments) => payments.chargeDescriptor)

export const getDefaultCard = createSelector([getCards], (cards) => {
  const cardIds = Object.keys(cards)
  const defaultCardId = cardIds.find((cardId) => cards[cardId].default === true) || null
  return cards[defaultCardId]
})

export const getCurrentDepotPaymentMethods = createSelector([getActiveDepot], (depot) => depot.payment || [])

export const getAllowACH = createSelector(
  [getCurrentDepotPaymentMethods, getPaymentProviders],
  (paymentMethods, paymentProviders) => {
    const depotAllowsAch = paymentMethods.includes('ACH')
    const depotHasAchProvider = !!paymentProviders?.[ACH_PAYMENT_METHOD]
    return depotAllowsAch && depotHasAchProvider
  }
)

// whether or not we allow ONLINE credit card payments
export const allowCreditCards = createSelector(
  [getCurrentDepotPaymentMethods, getPaymentProviders],
  (paymentMethods, paymentProviders) => {
    const depotAllowsCreditOnline = paymentMethods.includes('CreditCard')
    const depotHasCreditProvider = !!paymentProviders?.[CREDIT_PAYMENT_METHOD]
    return depotAllowsCreditOnline && depotHasCreditProvider
  }
)

export const allowCash = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('Cash')
)

export const allowCreditPresent = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('CreditPresent')
)

export const allowDebitPresent = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('DebitPinPresent')
)

export const allowDebitCashBack = createSelector([getCurrentDepotPaymentMethods], (paymentMethods) =>
  paymentMethods.includes('DebitCashBack')
)
// check to see if allowed and there is an enabled provider
export const allowDebitOnline = createSelector(
  [getCurrentDepotPaymentMethods, getPaymentProviders],
  (paymentMethods, paymentProviders) => {
    const depotAllowsDebitOnline = paymentMethods.includes('DebitOnline')
    const depotHasDebitProvider = !!paymentProviders?.[DEBIT_CARD_ONLINE_METHOD]
    return depotAllowsDebitOnline && depotHasDebitProvider
  }
)

export const isCashOnly = createSelector(
  [getCurrentDepotPaymentMethods],
  (paymentMethods) => paymentMethods.length === 1 && paymentMethods[0] === 'Cash'
)

// We might want to place this in checkout as a UI property
export const getNeedsPaymentMethod = createSelector(
  [
    allowCreditCards,
    allowCash,
    getPayments,
    allowCreditPresent,
    allowDebitPresent,
    allowDebitCashBack,
    getAllowACH,
    allowDebitOnline
  ],
  (
    allowCreditCards,
    allowCash,
    payments,
    allowCreditPresent,
    allowDebitPresent,
    allowDebitCashBack,
    allowsACH,
    allowDebitOnline
  ) => {
    const { activePaymentId } = payments
    if (
      (allowCash && activePaymentId === CASH_PAYMENT_METHOD) ||
      (allowCreditPresent && activePaymentId === PRESENT_CREDIT_CARD_PAYMENT_METHOD) ||
      (allowDebitPresent && activePaymentId === PRESENT_DEBIT_CARD_PAYMENT_METHOD) ||
      (allowDebitCashBack && activePaymentId === DEBIT_CASH_BACK_PAYMENT_METHOD) ||
      (allowsACH && activePaymentId === ACH_PAYMENT_METHOD) ||
      (allowDebitOnline && activePaymentId === DEBIT_CARD_ONLINE_METHOD) ||
      (allowCreditCards && activePaymentId && !NOT_ONLINE_CARD_PAYMENT_METHOD_IDS.includes(activePaymentId))
    ) {
      return false
    }
    return true
  }
)

export const getHasActivePaymentId = createSelector([getActivePaymentId], (activePaymentId) => {
  // since one of our enums is 0 we need to account for that
  // any other falsey value should mean no payment method is set
  return activePaymentId || activePaymentId === 0
})

export const getHasCards = createSelector([getCards], (cards) => {
  return !isEmptyObject(cards)
})
export const getDispensaryId = createSelector([getActiveDepot], (activeDepot) => {
  const dispensary = activeDepot.dispensary
  return dispensary ? dispensary.id : null
})
