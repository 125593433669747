export const ORDER_STATUS_TYPES = {
  ACCEPTED: 'Accepted',
  CANCELED_BY_DRIVER: 'CanceledByDriver',
  CANCELED_BY_PATIENT: 'CanceledByPatient',
  DECLINED: 'Declined',
  DELIVERED: 'Delivered',
  IN_ROUTE: 'InRoute',
  NO_RESPONSE: 'NoResponse',
  REFUNDED: 'Refunded',
  REMOVED: 'Removed',
  REROUTE: 'Reroute',
  REROUTE_TIMEOUT: 'RerouteTimeout',
  REQUESTED: 'Requested',
  TIMEOUT: 'Timeout'
}

export const ORDER_PROGRESS_TYPE = {
  ORDER_RECEIVED: 'ORDER_RECEIVED',
  ORDER_BEING_PREPARED: 'ORDER_BEING_PREPARED',
  ORDER_ON_THE_WAY: 'ORDER_ON_THE_WAY',
  ORDER_ARRIVING: 'ORDER_ARRIVING',
  ORDER_DELIVERED: 'ORDER_DELIVERED'
}

export const ORDER_PROGRESS_TYPE_OBJECT = {
  [ORDER_PROGRESS_TYPE.ORDER_RECEIVED]: {
    id: 'order-received',
    name: 'Order received',
    cancellable: true,
    addLine: false
  },
  [ORDER_PROGRESS_TYPE.ORDER_BEING_PREPARED]: {
    id: 'assigning-driver',
    name: 'Order is being prepared',
    cancellable: true,
    addLine: true
  },
  [ORDER_PROGRESS_TYPE.ORDER_ON_THE_WAY]: {
    id: 'driver-incoming',
    name: 'Driver is on the way!',
    cancellable: true,
    addLine: true
  },
  [ORDER_PROGRESS_TYPE.ORDER_ARRIVING]: {
    id: 'order-arriving',
    name: 'Order is arriving',
    cancellable: false,
    addLine: true
  },
  [ORDER_PROGRESS_TYPE.ORDER_DELIVERED]: {
    id: 'order-delivered',
    name: 'Order has been delivered',
    cancellable: false,
    addLine: true
  }
}

export const ORDER_PROGRESS_TYPE_ARRAY = [
  ORDER_PROGRESS_TYPE.ORDER_RECEIVED,
  ORDER_PROGRESS_TYPE.ORDER_BEING_PREPARED,
  ORDER_PROGRESS_TYPE.ORDER_ON_THE_WAY,
  ORDER_PROGRESS_TYPE.ORDER_ARRIVING
]

export const ORDER_CANCELED_TYPE_ARRAY = [
  ORDER_STATUS_TYPES.CANCELED_BY_DRIVER,
  ORDER_STATUS_TYPES.CANCELED_BY_PATIENT,
  ORDER_STATUS_TYPES.REMOVED,
  ORDER_STATUS_TYPES.REROUTE_TIMEOUT,
  ORDER_STATUS_TYPES.TIMEOUT
]

export const ORDER_ETA_FLOOR_MINUTES = 3

export const ORDER_STATUS_KEYS = [
  'deliveryExpectedAt',
  'deliveryExpectedStartAt',
  'deliveryExpectedEndAt',
  'deliveredAt',
  'firstInQueue',
  'status'
]

export default {
  ORDER_STATUS_TYPES,
  ORDER_PROGRESS_TYPE,
  ORDER_PROGRESS_TYPE_OBJECT,
  ORDER_PROGRESS_TYPE_ARRAY,
  ORDER_CANCELED_TYPE_ARRAY,
  ORDER_ETA_FLOOR_MINUTES,
  ORDER_STATUS_KEYS
}
