import { createSelector } from 'reselect'

import { etaInterval, etaIntervalFormatted } from 'redux/misc-selectors/time-range'

export const getEta = (state) => state.eta

export const getEtaError = createSelector([getEta], (eta) => eta.error)

const ONE_SECOND_IN_MILLISECONDS = 1000

const getEstimateTime = createSelector([getEta], (eta) => `${eta.estimatedDeliveryDateTime}Z`)

export const getEstimateTimeFormatted = createSelector([getEstimateTime], (eta) => {
  const etaInMinutes = (Date.parse(eta) - Date.now()) / ONE_SECOND_IN_MILLISECONDS
  const etaIntervalRange = etaInterval(etaInMinutes)
  if (isNaN(etaIntervalRange.low)) return '-'
  return etaIntervalFormatted(etaIntervalRange)
})
